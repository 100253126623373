import React from 'react';
import { Grid, Typography, Chip, Badge, Tooltip, Avatar, Card, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccessTime, NotificationsActiveOutlined, PeopleAlt, ChatBubbleOutline, FlightOutlined, PaymentOutlined as PaymentIcon, AccountCircle } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import { formatWithOptions, isSameDay, isSameYear } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import { Paths } from '../../constants';
import BookingState, { getBookingStateColor } from './BookingState';

export const STATUS_NOT_ASSIGNED = 0;
export const STATUS_ASSIGNED = 1;
export const STATUS_REQUESTED = 4;
export const STATUS_ACCEPTED = 6;
export const STATUS_REJECTED = 7;
export const STATUS_RESERVED = 2;
export const STATUS_CANCELLED = 3;

const dateWithoutYearFormatter = formatWithOptions({ locale: es }, 'd LLL');
const dateWithYearFormatter = formatWithOptions({ locale: es }, 'd LLL yyyy');
const timeFormatter = formatWithOptions({ locale: es }, 'HH:mm');
const dateFlightFormatter = formatWithOptions({ locale: es }, 'HH:mm (d LLL)');


export default function BookingCard({booking, lite}) {

  const history = useHistory();
  const classes = useStyles();
  const stateColor = getBookingStateColor(booking.info);

  const onOpen = (bookingId) => {
    history.push(`${Paths.PROVIDERS_BOOKINGS}/${bookingId}`);
  };

  const peopleText = booking.info.pax === 1 ? "persona" : "personas";

  const { startDate, startTime, endDate, endTime } = booking.info;

  let dateComponent = null;
  const fromDate = new Date(`${startDate} ${startTime}`);
  const now = new Date();
  
  if (endDate && endTime) {
    const toDate = new Date(`${endDate} ${endTime}`);
    const dateFromFormatter = isSameYear(now, toDate) ? dateWithoutYearFormatter : dateWithYearFormatter;
    const dateToFormatter = isSameYear(fromDate, now) ? dateWithoutYearFormatter : dateWithYearFormatter;
    
    dateComponent = (isSameDay(fromDate, toDate)) ?
      (<span>{dateFromFormatter(fromDate)} <strong>{timeFormatter(fromDate)}</strong> - <strong>{timeFormatter(toDate)}</strong></span>) :
      (<span>{dateFromFormatter(fromDate)} <strong>{timeFormatter(fromDate)}</strong> - {dateToFormatter(toDate)} <strong>{timeFormatter(toDate)}</strong></span>);
  }
  else {
    const dateFormatter = isSameYear(fromDate, now) ? dateWithoutYearFormatter : dateWithYearFormatter;
    dateComponent = (<span>{dateFormatter(fromDate)} <strong>{timeFormatter(fromDate)}</strong></span>);
  }

  const actionRequired = booking.confirm || booking.info.status == STATUS_REQUESTED;

  let flightInfo = null;
  if (booking.info.flight) {
    const { date, time, flightNumber, terminal, flightType } = booking.info.flight;
    flightInfo = `${flightNumber ? `Vuelo ${flightNumber} - ` : ""}${flightType == 1 ? "Llegada" : "Salida"} ${dateFlightFormatter(new Date(`${date} ${time}`))}${terminal ? ` en la terminal ${terminal}` : ""}`;
  }

  return (
    <Grid item xs={12} lg={6}>
      <Card className={classes.item} elevation={2} style={{ borderLeft: '8px solid gray', borderLeftColor: stateColor }}>
        <CardActionArea onClick={_ => onOpen(booking.bookingId)}>

          <div className={classes.card}>

            {/* <div className={classes.cardLine} style={{ backgroundColor: stateColor }}></div> */}
            <div className={classes.cardContent}>

              <div style={{ maxWidth: '100%' }}>
                <Typography variant="subtitle1" color="primary" style={{ marginLeft: '8px', fontWeight: 'bold' }}>{booking.info.serviceName}</Typography>
              </div>
              
              <div style={{ display: 'flex' }}>
                <Chip className={classes.label} variant="outlined" icon={<AccountCircle />} label={booking.info.client?.toUpperCase() || "(Sin información de cliente)"} />
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Chip className={classes.label} variant="outlined" icon={<AccessTime />} label={dateComponent} />
                <Chip className={classes.label} variant="outlined" icon={<PeopleAlt />} label={`${booking.info.pax} ${peopleText}`} />
              </div>
            
              {/* <div style={{ padding: '3px', minHeight: '35px' }}>
                {(booking.tags || []).map(tag => (
                  <Chip key={tag} className={classes.tag} size="small" variant="default" label={tag} />
                ))}
              </div> */}

              <div style={{ padding: '5px', minHeight: lite ? undefined :'42px' }}>
                {booking.info.advancePayment > 0 && <Chip key={-1} style={{ margin: '3px 10px 3px 0px' }}
                  variant="outlined" color="secondary"
                  size="small"
                  avatar={<Avatar>!</Avatar>}
                  label={<span>Pago de cliente de <strong>{`${booking.info.advancePayment || 0} €`}</strong></span>}
                />}
                {booking.info.characteristics.map((char, idx) => (
                  <Chip key={idx} style={{ margin: '3px 10px 3px 0px' }}
                    variant="default"
                    size="small"
                    avatar={<Avatar>{idx + 1}</Avatar>}
                    label={char}
                  />
                ))}
              </div>

              <div style={{ backgroundColor: '#F6F6F6', width: '100%', height: '34px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>

                <BookingState booking={booking} />
                {/* <div style={{ display: 'flex', paddingLeft: '8px' }}>
                  <Cancel fontSize="small" style={{ color: '#E84C3D' }} />
                  <Typography variant="body2" style={{ color: '#5B5B5B', fontWeight: 'bold', marginLeft: '8px' }}>CANCELADA</Typography>
                </div> */}
                
                <div style={{ display: 'flex', paddingLeft: '8px' }}>

                  {actionRequired && <BootstrapTooltip title="Reserva con acciones pendientes por parte del proveedor">
                    <div size="small" style={{ marginRight: '15px' }}><NotificationsActiveOutlined color="secondary" /></div>
                  </BootstrapTooltip>}

                  {flightInfo != null && <BootstrapTooltip title={flightInfo}>
                    <div size="small" style={{ marginRight: '15px' }}><FlightOutlined color="primary" /></div>
                  </BootstrapTooltip>}

                  {booking.payment != null && <BootstrapTooltip title={booking.payment.status == 1 ? "Información de pagos rechazada por el proveedor" : "Información de pagos disponible"}>
                    <div size="small" style={{ marginRight: '15px' }}><PaymentIcon color={booking.payment.status == 1 ? "secondary" : "primary"} /></div>
                  </BootstrapTooltip>}

                  {booking.totalMessages > 0 && <BootstrapTooltip title={`Chat con mensajes${booking.unreadMessages > 0 ? " pendientes de leer" : ""}`}>
                    <div size="small" style={{ marginRight: '15px' }}><Badge badgeContent={booking.unreadMessages} variant="standard" color="error"><ChatBubbleOutline color="primary" /></Badge></div>
                  </BootstrapTooltip>}
                  
                </div>
              </div>

            </div>
          </div>

        </ CardActionArea>
      </Card>
    </Grid>
  );
};


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    padding: '8px', 
    transition: 'all .20s linear',
    boxSshadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
    '&:hover' : {
      boxShadow: '-1px 1px 9px 0px rgba(0,0,0,0.4)',
    },
    // '&$itemHeader': {
    //   '&:hover' : {
    //     backgroundColor: '#dfe7fd',
    //   },
    // },
  },
  itemHeader: {
    backgroundColor: '#F5F5F5', 
    borderRadius: '5px 5px 0px 0px',
    borderBottom: '1px solid white',

    transition: 'all .20s linear',
    '&:hover' : {
      backgroundColor: '#dfe7fd', // faf3dd
      // backgroundColor: '#faf3dd',
    },
  },
  link: {
    marginRight: '10px',
    // color: 'darkorange',
  },
  logo: {
    height: '34px',
    margin: '10px',
  },

  card: {
    display: 'flex',
  },
  cardLine: {
    width: '8px',
    // backgroundColor: '#E84C3D',
    backgroundColor: 'lightgray',
    borderRadius: '2px',
  },
  cardContent: {
    width: '100%',
    padding: 0, //padding: '0 0 0 8px',
    // paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  label: {
    // borderRadius: '3px',
    border: '0px',
    marginRight: '8px',
    // color: '#5B5B5B',
    maxWidth: '100%',
  },
  tag: {
    borderRadius: '3px',
    // border: '0px',
    marginRight: '8px',
    color: '#5B5B5B',
  }
}));