import React from 'react';
import { Typography } from '@material-ui/core';
import { Cancel, HourglassEmpty, Done, DoneAll, Clear } from '@material-ui/icons';

export const STATUS_NOT_ASSIGNED = 0;
export const STATUS_ASSIGNED = 1;
export const STATUS_REQUESTED = 4;
export const STATUS_ACCEPTED = 6;
export const STATUS_REJECTED = 7;
export const STATUS_RESERVED = 2;
export const STATUS_CANCELLED = 3;

export const STATUS_LIST = [
  { id: -1, name: "Todos" },
  // { id: STATUS_NOT_ASSIGNED, name: "No asignado" },
  // { id: STATUS_ASSIGNED, name: "Asignado" },
  { id: STATUS_REQUESTED, name: "Pendiente" },
  { id: STATUS_ACCEPTED, name: "Aceptado" },
  { id: STATUS_REJECTED, name: "Rechazado" },
  { id: STATUS_RESERVED, name: "Reservado" },
  { id: STATUS_CANCELLED, name: "Cancelado" },
];

export const getBookingStateColor = (info) => {

  const { status } = info;

  if (status === STATUS_ACCEPTED) return 'green';
  else if (status === STATUS_REQUESTED) return 'lightblue';
  else if (status === STATUS_RESERVED) return 'green';
  else if (status === STATUS_REJECTED) return '#E84C3D';
  else if (status === STATUS_CANCELLED) return '#E84C3D';
  else return 'gray';
}

export default function BookingState({booking}) {

  if (!booking) return null;

  const { status } = booking.info;
  const color = getBookingStateColor(booking.info);
  let text = '...';
  if (status === STATUS_ACCEPTED) text = 'ACEPTADA';
  else if (status === STATUS_REQUESTED) text = 'PENDIENTE';
  else if (status === STATUS_RESERVED) text = 'RESERVADA';
  else if (status === STATUS_REJECTED) text = 'RECHAZADA';
  else if (status === STATUS_CANCELLED) text = 'CANCELADA';

  return (
    <div style={{ display: 'flex', paddingLeft: '8px' }}>
      {status === STATUS_ACCEPTED && <Done fontSize="small" style={{ color }} />}
      {status === STATUS_REQUESTED && <HourglassEmpty fontSize="small" style={{ color }} />}
      {status === STATUS_RESERVED && <DoneAll fontSize="small" style={{ color }} />}
      {status === STATUS_REJECTED && <Clear fontSize="small" style={{ color }} />}
      {status === STATUS_CANCELLED && <Cancel fontSize="small" style={{ color }} />}
      <Typography variant="body2" style={{ color: '#5B5B5B', fontWeight: 'bold', marginLeft: '8px' }}>{text}</Typography>
    </div>
  );
}