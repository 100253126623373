import React, { useEffect, useCallback } from 'react';
import { Grid, Typography, IconButton, Button, Card, CardHeader, CardActions, CardActionArea, Avatar } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, blue } from '@material-ui/core/colors';
import { format, startOfISOWeek, endOfISOWeek, add } from 'date-fns/fp';
import { useHistory } from "react-router-dom";
import { useLocalStore, action, thunk } from 'easy-peasy';
import { EventAvailable, QuestionAnswer } from '@material-ui/icons';
import { STATUS_ACCEPTED, STATUS_REJECTED } from './BookingCard';
import classnames from 'classnames';
import useNotifications from '../core/services/NotificationHook';
import bookingService from './services/BookingService';
import bookingImage from './images/reserva.png';
import messagesImage from './images/message.png';
import pendingImage from './images/pending.png';
import { STATUS_REQUESTED } from './BookingState';

const dateFormat = format('yyyy-MM-dd');

const BOOKINGS_NEW_EVENT = "BK:NEW";
const BOOKINGS_UPDATE_EVENT = "BK:UPD";
const BOOKINGS_STATE_EVENT = "BK:ST";
// const MESSAGES_READ_EVENT = "MSG:RD";
const MESSAGE_NEW = "MSG:NEW";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  warning: {
    backgroundColor: red[50],
  },
  warningAvatar: {
    backgroundColor: red[500],
  },
  ok: {
    backgroundColor: green[50],
  },
  okAvatar: {
    backgroundColor: green[500],
  },
  // avatar: {
  //   backgroundColor: red[500],
  // },
  avatarOk: {
    backgroundColor: green[500],
  },
  messageCard: {
    backgroundColor: green[50],
  },
  bookingCard: {
    backgroundColor: blue[50],
  },
  warningCard: {
    backgroundColor: red[50],
  },
  cover: {
    width: 151,
  },
  contentArea: {
    overflow: 'scroll',
    height: 'calc(100vh - 130px)',
  },
}));


const ProvidersHome = () => {

  const classes = useStyles();
  const history = useHistory();
  const { notifier } = useNotifications();

  const [state, actions] = useLocalStore(() => ({

    // STATE
    unreads: 0,
    confirms: 0,
    requests: 0,

    // ACTIONS
    setOverview: action((_state, {unreads, confirms, requests}) => { 
      _state.unreads = unreads;
      _state.confirms = confirms;
      _state.requests = requests;
    }),

    // THUNKS
    getOverviewInfo: thunk(async (_actions, _) => {

      const response = await bookingService.getBookingsOverview();
      if (response.succeeded) {
        _actions.setOverview(response.data);
      }
      else {
        // TODO: muestra error en pantalla...
        console.log(response.message)
      }
    }),

  }), []);

  useEffect(() => { actions.getOverviewInfo() }, []);

  useEffect(() => { 
    notifier.on(BOOKINGS_NEW_EVENT, notificationReceived);
    notifier.on(BOOKINGS_UPDATE_EVENT, notificationReceived);
    notifier.on(BOOKINGS_STATE_EVENT, notificationReceived);
    // notifier.on(MESSAGES_READ_EVENT, notificationReceived);
    notifier.on(MESSAGE_NEW, notificationReceived);

    return function cleanup() { 
      notifier.off(BOOKINGS_NEW_EVENT, notificationReceived);
      notifier.off(BOOKINGS_UPDATE_EVENT, notificationReceived);
      notifier.off(BOOKINGS_STATE_EVENT, notificationReceived);
      // notifier.off(MESSAGES_READ_EVENT, notificationReceived);
      notifier.off(MESSAGE_NEW, notificationReceived);
    };
  }, []);

  const notificationReceived = useCallback(() => actions.getOverviewInfo());
  // const bookingNew = () => { actions.getOverviewInfo(); }
  // const bookingUpdate = () => { actions.getOverviewInfo(); }
  // const bookingState = () => { actions.getOverviewInfo(); }
  // const messagesRead = () => { actions.getOverviewInfo(); }
  // const messageReceived = () => { actions.getOverviewInfo(); }

  const viewNotConfirmed = () => {

    history.push(`/providers/bookings?confirm=true`);
  }

  const viewStatus = (status) => {

    history.push(`/providers/bookings?status=${status}`);
  }

  const viewWeek = (days) => {

    const now = new Date().setDate(new Date().getDate() + days);
    const from = dateFormat(startOfISOWeek(now));
    const to = dateFormat(endOfISOWeek(now));  

    history.push(`/providers/bookings?fromDate=${from}&toDate=${to}`);
  }

  const pendings = state.requests + state.confirms > 0;

  return (
    <Grid container direction="column" style={{ marginLeft: '0', paddingRight: '0' }}>

      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton size="medium"><Home /></IconButton>
            <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'bold' }}>Portal de proveedores</Typography>
          </div>
        </div>
      </Grid>

  
      <Grid item container alignItems="flex-start" alignContent='flex-start' spacing={1} className={classes.contentArea}>

        <Grid item  md={12} xs={12}>
          <Card className={classes.bookingCard}>
            <CardActionArea onClick={_ => history.push('/providers/bookings')}>
              <CardHeader
                avatar={<Avatar>R</Avatar>}
                action={<img style={{ width: '60px', margin: '12px', opacity: '0.75' }} src={bookingImage} />}
                title="RESERVAS"
                subheader="Consulta reservas por estado o fecha"
              />
            </CardActionArea>
            <CardActions disableSpacing>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Button variant="text" color="primary" style={{ marginRight: '8px' }} onClick={_ => viewStatus(STATUS_ACCEPTED)}>Aceptadas</Button>
                <Button variant="text" color="primary" style={{ marginRight: '8px' }} onClick={_ => viewStatus(STATUS_REJECTED)}>Rechazadas</Button>
                <Button variant="text" color="primary" style={{ marginRight: '8px' }} onClick={_ => viewWeek(0)}>semana actual</Button>
                <Button variant="text" color="primary" style={{ marginRight: '8px' }} onClick={_ => viewWeek(7)}>próxima semana</Button>
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={12} xs={12}>
          <Card className={classnames({ [classes.warning]: pendings, [classes.ok]: !pendings })}>
            <CardActionArea onClick={_ => history.push('/providers/bookings')}>
              <CardHeader
                avatar={<Avatar className={classnames({ [classes.warningAvatar]: pendings, [classes.okAvatar]: !pendings })}>{state.requests + state.confirms}</Avatar>}
                action={<img style={{ width: '60px', margin: '12px', opacity: '0.65' }} src={pendingImage} />}
                title="RESERVAS"
                subheader="Pendientes de revisión"
              />
            </CardActionArea>
            <CardActions disableSpacing>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {state.requests > 0 && <Button variant="text" color="secondary" style={{ marginRight: '8px' }} onClick={_ => viewStatus(STATUS_REQUESTED)}>Por confirmar ({state.requests})</Button>}
                {state.confirms > 0 && <Button variant="text" color="secondary" style={{ marginRight: '8px' }} onClick={_ => viewNotConfirmed()}>Modificadas ({state.confirms})</Button>}
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={12} xs={12}>
          <Card className={classnames({ [classes.warning]: state.unreads > 0, [classes.ok]: state.unreads === 0 })}>
            <CardActionArea>
              <CardHeader
                avatar={<Avatar className={classnames({ [classes.warningAvatar]: state.unreads > 0, [classes.okAvatar]: state.unreads === 0 })}>{state.unreads}</Avatar>}
                action={
                  // <div style={{ margin: '20px' }}><EventAvailable fontSize="large" /></div>
                  <img style={{ width: '60px', margin: '12px', opacity: '0.70' }} src={messagesImage} />
                }
                title="MENSAJES"
                subheader="Pendientes de visualización"
              />
            </CardActionArea>
          </Card>
        </Grid>
        
      </Grid>
   

    </Grid>
  );
}

export default ProvidersHome;