import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, Popover } from '@material-ui/core';
import { Face, Close } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import useNotifications from './services/NotificationHook';


export default () => {

  const classes = useStyles();
  const history = useHistory();
  const { unbindToken } = useNotifications();
  const [anchorEl, setAnchorEl] = useState(null);
  const { session, loggedIn } = useStoreState(state => state.auth );
  const setUser = useStoreActions(state => state.auth.setUser);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const open = Boolean(anchorEl);

  const handleClick = (ev) => {
    if (loggedIn) {
      setAnchorEl(ev.currentTarget);
    }
    else {
      history.push('/login');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    await unbindToken();

    setAnchorEl(null);
    setUser(null);
    
    history.push('/login');
  }
  
  return (
    <>
      {loggedIn && <Button
        variant="text" 
        color="primary" 
        size={isMobile ? "large" : "medium"} 
        endIcon={loggedIn ? <Face /> : null} 
        classes={{ root: classes.root, label: classes.label }} 
        style={{ marginLeft: '20px' }}
        onClick={handleClick}
      >
        {!isMobile ? (session?.name || "...") : ""}
      </Button>}

      <Popover anchorEl={anchorEl} onClose={handleClose} open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: '5px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          {isMobile && <Button variant="text" disabled classes={{ label: classes.label }}>{session?.name || "..."}</Button>}

          <Button
            variant="text" 
            color="primary" 
            endIcon={<Close />} 
            onClick={onLogout}
          >
            CERRAR SESIÓN
          </Button>
        </div>
      </Popover>
    </>
  );
};

// export default SessionButton;

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      borderColor: 'white',
    },
  },
  label: {
    textTransform: 'none',
  },
  close: {
    padding: theme.spacing(1),
  },
}));