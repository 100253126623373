import React, { useEffect, useState } from 'react';
import { Grid, Typography, IconButton, Button, Tooltip, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';
import { ArrowBack, Payment as PaymentIcon, QuestionAnswer, Details as DetailsIcon, ListAlt } from '@material-ui/icons';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Scrollchor } from 'react-scrollchor';
import { useLocation } from "react-router-dom";
import BookingOverview from './BookingOverview';
import BookingPayment from './BookingPayment';
import Chat from '../core/Chat';
import Confirm from '../core/Confirm';
import { STATUS_ACCEPTED, STATUS_REJECTED, STATUS_REQUESTED } from './BookingState';

const AcceptButton = styled(Button)({
  background: 'green',
  color: 'white',
  transition: 'all .10s linear',
  '&:hover': {
    background: '#429741',
  },
});
const RejectButton = styled(Button)({
  background: '#E84C3D',
  color: 'white',
  transition: 'all .10s linear',
  '&:hover': {
    background: '#F76463',
  },
});

export default function BookingDetails() {

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { currentBooking: booking, bookingError, paymentError } = useStoreState((state) => state.providers);
  const { fetchBookingDetails, updateBookingStatus, confirmBooking } = useStoreActions((state) => state.providers);
  const [acceptOpened, setAcceptOpened] = useState(false);
  const [rejectOpened, setRejectOpened] = useState(false);
  const [confirmOpened, setConfirmOpened] = useState(false);

  const collapseAll = () => { setOverviewExpanded(false); setPaymentExpanded(false); }

  const [overviewExpanded, setOverviewExpanded] = useState(true);
  const onOverviewExpanded = (exp) => setOverviewExpanded(exp);
  const onDetails = () => { /*collapseAll();*/ setOverviewExpanded(true); }

  const [paymentExpanded, setPaymentExpanded] = useState(true);
  const onPaymentExpanded = (exp) => setPaymentExpanded(exp);
  const onPayment = () => { /*collapseAll();*/ setPaymentExpanded(true); }

  const [chatExpanded, setChatExpanded] = useState(true);
  const onChatExpanded = (exp) => setChatExpanded(exp);
  const onChat = () => { setChatExpanded(true); }

  const urlParams = new URLSearchParams(location.search);

  useEffect(() => { 
    const show = urlParams.get('show');
    if (show === 'payments') {
      
      collapseAll();
      setPaymentExpanded(true);
    }
  }, []); 

  useEffect(() => fetchBookingDetails(params.id), []);

  const onBack = () => {
    history.goBack();
  }

  const onBookingAccepted = async () => {
    onClose(true);
    await updateBookingStatus({ bookingId: params.id, status: STATUS_ACCEPTED });
  }

  const onBookingRejected = async () => {
    onClose(true);
    await updateBookingStatus({ bookingId: params.id, status: STATUS_REJECTED });
  }

  const onConfirm = async () => {
    onClose(true);
    await confirmBooking({ bookingId: params.id });
  }

  const onClose = async () => {
    setAcceptOpened(false);
    setRejectOpened(false);
    setConfirmOpened(false);
  }

  const status = booking?.info.status;
  const confirm = booking?.confirm || false;

  return (
    <>
      <Grid container direction="column" style={{ marginLeft: '0', paddingRight: '0' }}>

        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton size="medium" onClick={onBack}><ArrowBack /></IconButton>
              {!isMobile && <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'bold' }}>{booking?.info.serviceName}</Typography>}
            </div>
            {confirm && <Button variant="contained" color="secondary" onClick={_ => setConfirmOpened(true)}>Confirmar</Button>}
            {status === STATUS_REQUESTED && !confirm && <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>Reserva pendiente de aprovación</Typography>
              <AcceptButton variant="contained" style={{ marginLeft: '16px' }} onClick={_ => setAcceptOpened(true)}>Aceptar</AcceptButton>
              <RejectButton variant="contained" style={{ marginLeft: '16px' }} onClick={_ => setRejectOpened(true)}>Rechazar</RejectButton>
            </div>}
            {status !== STATUS_REQUESTED && !confirm && <div style={{ display: 'flex', alignItems: 'center' }}>
            
              <Scrollchor to="details" target="booking-container" beforeAnimate={onDetails} afterAnimate={_ => history.goBack()}>
                {/* <Tooltip title="Abre detalles de la reserva"><IconButton size="medium"><ListAlt /></IconButton></Tooltip> */}
                <Tooltip title="Abre detalles de la reserva">
                  <>
                    {isMobile ? 
                      <IconButton size="medium"><ListAlt /></IconButton> :
                      <Button size="medium" startIcon={<ListAlt />}>Detalles</Button>
                    }
                  </>
                </Tooltip>
              </Scrollchor>
              <Scrollchor to="payment" target="booking-container" beforeAnimate={onPayment} afterAnimate={_ => history.goBack()}>
                {/* <Tooltip title="Abre información de pago"><IconButton size="medium"><PaymentIcon /></IconButton></Tooltip> */}
                <Tooltip title="Abre información de pago">
                  <>
                    {isMobile ? 
                      <IconButton size="medium"><PaymentIcon /></IconButton> :
                      <Button size="medium" startIcon={<PaymentIcon />}>Pagos</Button>
                    }
                  </>
                </Tooltip>
              </Scrollchor>
              <Hidden lgUp>
                <Scrollchor to="chat" target="booking-container" beforeAnimate={onChat} afterAnimate={_ => history.goBack()}>
                  {/* <Tooltip title="Muestra mensajes de la reserva"><IconButton size="medium"><QuestionAnswer /></IconButton></Tooltip> */}
                  <Tooltip title="Muestra mensajes de la reserva">
                    <>
                      {isMobile ? 
                        <IconButton size="medium"><QuestionAnswer /></IconButton> :
                        <Button size="medium" startIcon={<QuestionAnswer />}>Chat</Button>
                      }
                    </>
                  </Tooltip>
                </Scrollchor>
              </Hidden>
            </div>}
          </div>
        </Grid>

        <div id="booking-container" style={{ overflowX: 'hidden', overflowY: 'scroll', height: `calc(100vh - 154px)` }}>
          <Grid item container alignItems="flex-start" spacing={2}>

            {confirm && <Grid item xs={12}>
              <Alert severity="error" style={{ padding: '16px 16px 0px 24px', marginBottom: '8px' }}>
                <AlertTitle>CONFIRMACIÓN DE LEÍDO</AlertTitle>
                Se necesita confirmación por parte del proveedor. <strong>Ejecutar confirmación!</strong>
              </Alert>
            </Grid>}

            <Grid item lg={7} xs={12}>
              <div id="details">
                <BookingOverview booking={booking} lite={isMobile} bookingError={bookingError} expand={overviewExpanded} onExpand={onOverviewExpanded} />
              </div>
              <div id="payment" style={{ marginTop: '16px' }}>
                <BookingPayment bookingId={params.id} lite={isMobile} paymentError={paymentError} expand={paymentExpanded} onExpand={onPaymentExpanded} />
              </div>
            </Grid>

            <Grid item lg={5} xs={12}>
              <div id="chat">
                <Chat context="bookings" entity={params.id} expand={chatExpanded} onExpand={onChatExpanded} />
              </div>
            </Grid>
            
          </Grid>
        </div>
      </Grid>

      <Confirm title="ACEPTAR RESERVA" body="Aceptar la petición de reserva. Confirma la disponibilidad para este servicio" open={acceptOpened} onClose={onClose}>
        <Button onClick={onClose} variant="text">Volver</Button>
        <AcceptButton onClick={onBookingAccepted} autoFocus>Reservar</AcceptButton>
      </Confirm>

      <Confirm title="RECHAZAR RESERVA" body="Rechazar la petición de reserva. Confirma que no tienes disponibilidad o no te interesa este servicio" open={rejectOpened} onClose={onClose}>
        <Button onClick={onClose} variant="text">Volver</Button>
        <RejectButton onClick={onBookingRejected} autoFocus>Rechazar</RejectButton>
      </Confirm>

      <Confirm title="CONFIRMACIÓN DE LECTURA" body="Envía a OYDE la confirmación de que has leído los cambios en la reserva" open={confirmOpened} onClose={onClose}>
        <Button onClick={onClose} variant="text">Volver</Button>
        <Button onClick={onConfirm} variant="contained" color="primary" autoFocus>Confirmar</Button>
      </Confirm>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  contentArea: {
    overflow: 'scroll',
    height: 'calc(100vh - 170px)',
  },
  buttonLabel: {
    textTransform: 'none',
  },
}));