// import './App.css';
import { StoreProvider, useStoreRehydrated } from 'easy-peasy';
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { store } from './store';
import { useEffect } from 'react';
import Shell from './Shell';
import useNotifications from './modules/core/services/NotificationHook';


function WaitForStateRehydration({ children }) {
  const isRehydrated = useStoreRehydrated();
  const { startup } = useNotifications();

  useEffect(() => {
    if (isRehydrated) {
      console.log("REHYDRATED!!");

      startup();
    }
  }, [isRehydrated]);

  return isRehydrated ? children : <span>LOADING...</span>;
}

function App() {

  return (
    <StoreProvider store={store}>
      <SnackbarProvider maxSnack={3}>
        <WaitForStateRehydration>
          <Router>

            <Shell />

          </Router>
        </WaitForStateRehydration>
      </SnackbarProvider>
    </StoreProvider>
  );
}

export default App;