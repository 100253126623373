import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Button, Badge, Hidden } from '@material-ui/core';
import { Menu, MenuOpen, ChatBubble, Notifications } from '@material-ui/icons';
import { useStoreState, useStoreActions } from 'easy-peasy';
import logoImage from './images/oyde.png';
import SessionButton from './SessionButton';

const useStyles = makeStyles(theme => ({
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '42px',
    margin: '5px 10px 0 0',
    // marginRight: '10px',
    // marginBottom: '10px'
  },
  buttonLabel: {
    textTransform: 'none',
  }
})); // #FBBC05


const Navbar = () => {
  const classes = useStyles();
  const collapsed = useStoreState((state) => state.menu.collapsed);
  const toggleMenu = useStoreActions((actions) => actions.menu.toggleMenu);
  const { messages, notifications } = useStoreState((state) => state.providers);
  const { loggedIn } = useStoreState((state) => state.auth );
  // const [open, setOpen] = useState(false);

  // console.log('collapsed:', collapsed)
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // <AppBar position="static" color="transparent" style={{ color: '#222222', backgroundColor: '#01226B' }}>
  // <AppBar position="static" color="transparent" style={{ color: '#222222', backgroundColor: '#76BEDC' }}></AppBar>

  return (
    <AppBar position="absolute" color="primary" >
      <Toolbar>
        {loggedIn && <Hidden mdUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={toggleMenu}
          >
            {collapsed && <Menu />}
            {!collapsed && <MenuOpen />}
          </IconButton>
        </Hidden>}

        <img className={classes.logo} src={logoImage} alt="Logo" />

        <Hidden xsDown>
          <Typography variant="subtitle1" className={classes.title} style={{ color: 'white', fontWeight: 'bold' }}>
            Proveedores
          </Typography>
        </Hidden>

        <div style={{ flexGrow: 1 }} />
        
        {/* <Button color="inherit" onClick={handleOpen}>
          Signup
        </Button> */}

        {/* <IconButton size="small" style={{ marginRight: '30px', color: 'white' }}>
          <Badge badgeContent={notifications.length} variant="standard" color="error">
            <Notifications />
          </Badge>
        </IconButton> */}

        {/* <IconButton size="small" style={{ marginRight: '30px', color: 'white' }}>
          <Badge badgeContent={messages.length} variant="standard" color="error">
            <ChatBubble />
          </Badge>
        </IconButton> */}

        <SessionButton />

      </Toolbar>
      {/* <ModalDialog open={open} handleClose={handleClose} /> */}
      
    </AppBar>
  );
}

export default Navbar;