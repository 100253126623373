import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import { EventAvailable, Warning, Payment, Settings, AssignmentInd, Home } from '@material-ui/icons';
import { useStoreActions } from 'easy-peasy';
import { useHistory } from "react-router-dom";

const Paths = {
  HOME: "/providers",
  BOOKINGS: "/providers/bookings",
  PAYMENTS: "/providers/payments",
  REGISTRIES: "/providers/registry",
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: 360,
    minWidth: 180,
    marginTop: '0px',
    marginRight: '8px',
    backgroundColor: '#FAFAFA', //theme.palette.background.paper,
  },
}));



export default function ProvidersMenu() {
  const classes = useStyles();
  const history = useHistory();
  const collapse = useStoreActions((actions) => actions.menu.collapse);
  const [currentPath, setCurrentPath] = useState(history.location.pathname);

  const isPath = (targetPath) => {
    return currentPath.startsWith(targetPath)
  }

  const isExactPath = (targetPath) => {
    return currentPath === targetPath;
  }

  // Subscripción a eventos del historial para controlar la ruta actual.
  useEffect(() => {

    const unsubscribe = history.listen((location, action) => {
      setCurrentPath(location.pathname);
    });
    return function cleanup() {
      unsubscribe();
    };
  }, []);

  const handleListItemClick = (event, targetPath) => {

    collapse();
    setCurrentPath(targetPath);
    history.push(targetPath);
  };

  return (
    <div className={classes.root}>

      <List component="nav" aria-label="main mailbox folders" dense={true}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            MENU PRINCIPAL
          </ListSubheader>
        }
      >
        
        <ListItem button selected={isExactPath(Paths.HOME)} onClick={(event) => handleListItemClick(event, Paths.HOME)}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>

        <ListItem button selected={isPath(Paths.REGISTRIES)} onClick={(event) => handleListItemClick(event, Paths.REGISTRIES)}>
          <ListItemIcon>
            <AssignmentInd />
          </ListItemIcon>
          <ListItemText primary="Registro" />
        </ListItem>

        <ListItem button selected={isPath(Paths.BOOKINGS)} onClick={(event) => handleListItemClick(event, Paths.BOOKINGS)}>
          <ListItemIcon>
            <EventAvailable />
          </ListItemIcon>
          <ListItemText primary="Reservas" />
        </ListItem>

        <ListItem button selected={isPath(Paths.PAYMENTS)} onClick={(event) => handleListItemClick(event, Paths.PAYMENTS)}>
          <ListItemIcon>
            <Payment />
          </ListItemIcon>
          <ListItemText primary="Pagos" />
        </ListItem>

        <ListItem button selected={false} onClick={(event) => handleListItemClick(event, Paths.BOOKINGS)} disabled>
          <ListItemIcon>
            <Warning />
          </ListItemIcon>
          <ListItemText primary="Incidencias" />
        </ListItem>

        <ListItem button selected={false} onClick={(event) => handleListItemClick(event, Paths.BOOKINGS)} disabled>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Configuración" />
        </ListItem>

      </List>
      <Divider />
    </div>
  );
}