import { action, computed } from 'easy-peasy';

export const menuStore = {

  collapsed: true,

  collapse: action (state => {
    state.collapsed = true;
  }),
  toggleMenu: action((state) => {
    state.collapsed = !state.collapsed;
  }),
};

export const authStore = {

  token: null,
  notificationsToken: null,
  session: computed((state) => state.token ? parseJwt(state.token) : {
    userId: null,
    name: null,
    roles: [],
    providers: [],
  }),
  loggedIn: computed((state) => state.token ? true : false),

  setUser: action((state, token) => {
    state.token = token;
  }),
  setNotificationsToken: action((state, token) => {
    state.notificationsToken = token;
  }),
};

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};