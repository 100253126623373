import { get, post, remove } from '../../../http';

// const distinct = (array) => {
//   return array.filter((elem, pos, arr) => arr.indexOf(elem) == pos);
// }

class AuthService {

  login = (username, password) => post("/auth/login", { username, password }, null);
}

export default new AuthService();