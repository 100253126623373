// import './App.css';
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useStoreRehydrated, useStoreState } from 'easy-peasy';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import NavBar from './modules/core/NavBar';
import Providers from './modules/providers/Providers';
import Login from './modules/core/Login';
import { Paths } from './constants';
import { useEffect } from 'react';


const useStyles = makeStyles((theme) => ({
  page: {
    padding: theme.spacing(1), //theme.spacing(3),
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`
    },
    [theme.breakpoints.up("sm")]: {
      height: `calc(100vh - 64px)`
    },
  },
  pad: {
    height: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: 48
    },
    [theme.breakpoints.up("sm")]: {
      height: 64
    }
  }
}));

function Shell() {

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isRehydrated = useStoreRehydrated();
  const { session, loggedIn } = useStoreState(state => state.auth );

  useEffect(() => {
    const unsubscribe = history.listen((location, action) => locationChecker(location.pathname));
    return function cleanup() {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isRehydrated) { locationChecker(location.pathname); }
  }, [isRehydrated]);

  const locationChecker = (path) => {
    if (loggedIn) {
      if (path === '/' && session.roles.includes("PROVIDER")) {
        history.replace(Paths.PROVIDERS);
      }
    }
    else {
      if (path === '/') {
        history.replace(Paths.LOGIN);
      }
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar />
      </Grid>
      <Grid item xs={12}>

        <div >
          <div className={classes.pad} />
          <div id="page" className={classes.page}>

            <Switch>
              <Route exact path={Paths.LOGIN}>
                <Login />
              </Route>
              <Route path={Paths.PROVIDERS}>
                <Providers />
              </Route>
            </Switch>

          </div>
        </div>
        
      </Grid>
    </Grid>
  );
}



export default Shell;
