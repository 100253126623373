import { get, post, remove } from '../../../http';

const UNKNOWN_ERROR = { code: 0, message: 'Error desconocido', succeeded: false };

class ChatService {

  getMessages = (context, entity) => get(`/chats/${context}/${entity}`, []);
  getMetadata = (context, entities) => get(`/chats/${context}/metadata?entities=${entities.join(',') || ""}`, null);
  addMessage = (context, entity, text) => post("/chats", { context, entity, text }, UNKNOWN_ERROR);
  confirmRead = (context, entity) => get(`/chats/${context}/${entity}/metadata`, UNKNOWN_ERROR); 
}

export default new ChatService();